import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  // max-width: 340px;
  max-width: 436px;
  width: 100%;
  z-index: 5;
  border: 1px solid rgba(255,255,255,10%);
  border-radius: 20px;
  // box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background: #875313;
  backdrop-filter: blur(140px);
  min-height: 414.5px;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
