import { createGlobalStyle } from 'styled-components'
import Aeonik from './AeonikTRIAL-Regular.otf';

const GlobalStyle = createGlobalStyle`

@font-face{
  font-family: 'Aeonik';
  src: url('${Aeonik}') format('opentype'); 
}



* {
  font-family: Aeonik
}

  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background: url('/images/new_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 136vh;
    overflow: hidden;
    img {
      height: auto;
      max-width: 100%;
    }

  }

  .row{
    width:100%;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .col{
    float: left;
    width:33%
  }

  @media (max-width: 500px) {
    body {
      background: url('/images/new_bg.png'); 
      overflow: visible;
    }

    .col{
      width:100%;
    }

    footer{
      position: relative!important;
    }
  }

`

export default GlobalStyle
